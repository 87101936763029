html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    font-size: 0.7em;
}

* {
    font-family: "Rubik", sans-serif;
}

#root,
html {
    min-height: 100%; /*min height mi nefunguje v admin*/
}

.frame {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.frame > * {
    flex: 1 1 50%;
}

#editor {
    font-family: monospace;
    padding: 0.5em;
    background: #444;
    color: white;
}

canvas {
    width: 100%;
    height: 100%;
}

.faded {
    opacity: 0.5;
    transition: opacity 0.5s;
    cursor: default;
}

.faded:hover {
    opacity: 1;
}

.svg-inline--fa {
    margin-right: 0.3em;
}

@import "../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../node_modules/react-date-range/dist/styles.css";
@import "../node_modules/react-date-range/dist/theme/default.css";

/* CSS variables. */
:root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(
            --PhoneInputCountrySelect-marginRight
    );
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: inherit;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.carousel .control-dots {
    bottom: 10px !important;
}

.drag {
    -webkit-app-region: drag;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

.myDatePickerWrapper {
    width: 100%;
}

.myCustomDatePickerInput {
    width: 100%;
}

select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 1px !important;
    text-overflow: "";
}

.input-range {
    margin-bottom: 160px;
}

.DateRangePicker__Month {
    margin: 0 !important;
    width: 244px;
}

*:focus {
    outline: none;
}

.active {
    background-color: #dcebe7 !important;
    color: #666 !important;
}

.selected {
    background-color: #2c3a53;
    color: #fff !important;
}

.selected:hover {
    background-color: #125e4a !important;
    color: white !important;
}

.selected:hover .hoveredColor {
    color: white !important;
}

.transparent {
    background-color: #fff !important;
    color: #000 !important;
}

.block {
    display: block !important;
}

.none {
    display: none;
}

.hide {
    min-width: 0 !important;
    width: 0 !important;
}

.maxWidth {
    width: 100%;
}

.showIcon {
    display: none;
}

.container {
    display: flex;
    flex-flow: nowrap column;
    overflow: hidden;
}

.sideBarResizeContainer {
    width: 30%;
}

.customHandle {
    cursor: ew-resize;
    width: 6px;
    height: 100px;
    margin: 0px -3px;
    background: #2c3a53;
    color: red;
    border-radius: 3px;
    text-align: center;
    z-index: 99999;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.customResizeBorder {
    cursor: ew-resize;
    width: 2px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: visible;
    z-index: 9999;
}

.customResizeBorder.mode-dark {
    background: #333;
}

.PhoneInput {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-out;
    width: 100%;
}

.PhoneInputInput {
    flex: 1;
    height: 32px;
    min-width: 0;
    border: none;
    font-size: 16px;
    font-family: "Work Sans";
    border-bottom: 1px solid #d8d8d8;
}

.PhoneInputInput:focus {
    border-bottom: 2px solid #2c3a53;
}

.PhoneInputCountryIcon {
    width: calc(
            var(--PhoneInputCountryFlag-height) *
            var(--PhoneInputCountryFlag-aspectRatio)
    );
    height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIconImg {
    /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
             Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
    height: 32px;
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding-right: var(--PhoneInputCountrySelect-marginRight);
    border-bottom: 1px solid #d8d8d8;
}

.PhoneInputCountrySelect {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 1px !important;
    text-overflow: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: none !important;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect option {
    border: 1px solid red !important;
}

.PhoneInputCountrySelect[disabled] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
+ .PhoneInputCountryIcon
+ .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus
+ .PhoneInputCountryIcon
.PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.MuiDialog-paperWidthSm {
    max-width: 1000px !important;
}

.select__menu {
    z-index: 999999999999 !important;
}
